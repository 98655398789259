import axios from 'axios';
import { ClientForJson, Client } from '../../utils/client';
import {SetterOrUpdater} from "recoil";
import {TRoom} from "../../types/townadmin/Room";

export const getDataSync = async (
  setRooms: SetterOrUpdater<TRoom[]>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return ClientForJson.get('/townadmin/api/v1/room/getRooms')
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      if (data.status == 200) {
        const rooms = data.data
        const dispData: TRoom[] = []
        rooms.map((room: any) => {

          let addData: TRoom = {
            id: room.id,
            available_time: room.available_time,
            cleaning_time: room.cleaning_time,
            room_name: room.room_name,
            room_price: room.room_price,
            spot_id: room.spot_id,
            use_user_max: room.use_user_max,
            use_user_min: room.use_user_min,
            use_user_no_limit_flg: room.use_user_no_limit_flg,
            adult_price: room.adult_price,
            child_price: room.child_price,
            room_available_start_time: room.room_available_start_time,
            room_available_end_time: room.room_available_end_time,
            pricing_flg: room.pricing_flg,
            thumbnail_path: room.thumbnail,
            businessHours: room.businessHours,
            note: room.note,
            show_flg: room.show_flg
          }
          const image1 = room.roomImages.find((item: any) => item.image_key == 'image1')?.path
          const image2 = room.roomImages.find((item: any) => item.image_key == 'image2')?.path
          const image3 = room.roomImages.find((item: any) => item.image_key == 'image3')?.path

          if (image1) {
            addData['image1_path'] = image1
          }

          if (image2) {
            addData['image2_path'] = image2
          }

          if (image3) {
            addData['image3_path'] = image3
          }

          dispData.push(addData)
        })

        setRooms(dispData)
      } else if (data.status == 400) {
        setErrorMsg(data.message)
      }
    })
};

export const updateRooms = async (
  rooms: TRoom[],
  setSuccessMsg: SetterOrUpdater<string>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return Client.post('/townadmin/api/v1/room/updateRooms', rooms)
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      console.log(data);
      const result = data.data
      if (result.status == 200) {
        setSuccessMsg(result.message)
      } else if (result.status == 400) {
        setErrorMsg(result.message)
      }
    })
};

export const deleteRoom = async (
  roomId: number,
  setSuccessMsg: SetterOrUpdater<string>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return await ClientForJson.post('/townadmin/api/v1/room/deleteRoom', {room_id: roomId})
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then((data) => {
      const result = data.data
      if (result.status == 200) {
        setSuccessMsg(result.message)
        return true
      } else if (result.status == 400) {
        setErrorMsg(result.message)
        return false
      } else {
        return false
      }
    })
};
