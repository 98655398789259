import axios from 'axios';

async function getCsrfToken() {
  const response = await axios.get('/get_csrf_token');
  return response.data.csrfToken;
}

export const Client = axios.create({
  // baseURL: 'http://localhost:80',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true,
});

Client.interceptors.request.use(async config => {
  config.headers['X-CSRF-Token'] = await getCsrfToken();
  return config;
});

export const ClientForJson = axios.create({
  // baseURL: 'http://localhost:80',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

ClientForJson.interceptors.request.use(async config => {
  config.headers['X-CSRF-Token'] = await getCsrfToken();
  return config;
});

export default Client;