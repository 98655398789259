import React, { Fragment, useMemo, useState, useCallback, useEffect } from 'react'
import { Calendar, momentLocalizer, NavigateAction, View, Views, ToolbarProps, ResourceHeaderProps } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import { TResourceMap, TEvent, TReserve, TBlock, TModalData } from "../../../types/townadmin/Calender";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  globalBlock,
  globalErrorMsg,
  globalLoading,
  globalReserve,
  globalRoom,
  globalSuccessMsg
} from "../../../strages/GlobalState";
import { TRoom } from "../../../types/townadmin/Room";
import { getBlocks, getReserves, getCloseFlg, updateReserves } from "../../../service/townadmin/Calender";
import { RegistModal } from "./RegistModal";
import { find } from "lodash";
import { FaRegCalendarAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'

const DnDCalendar = withDragAndDrop(Calendar)

type TProps = {
  events: TEvent[]
  resourceMaps: TResourceMap[]
  setEvents: any
  rooms: TRoom[]
}

// 曜日を漢字に変換する関数
const convertDayToJapanese = (day: number) => {
  const daysInJapanese = ["日", "月", "火", "水", "木", "金", "土"];
  return daysInJapanese[day];
}




// カスタムツールバーの作成
class CustomToolbar extends React.Component<ToolbarProps & {
  getReserves: Function,
  setReserves: Function,
  getCloseFlg: Function,
  setCloseFlg: Function,
  setErrorMsg: Function
}> {

  state = {
    isModalOpen: false
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };


  componentDidMount() {
    this.props.getCloseFlg(this.props.setCloseFlg, this.props.setErrorMsg, moment(this.props.date).format('YYYYMMDD'));
  }

  componentDidUpdate(prevProps: ToolbarProps & {
    getReserves: Function,
    setReserves: Function,
    getCloseFlg: Function,
    setCloseFlg: Function,
    setErrorMsg: Function
  }) {
    // 日付が変更された場合にのみgetReservesを呼び出す
    if (this.props.date !== prevProps.date) {
      this.props.getReserves(this.props.setReserves, this.props.setErrorMsg, moment(this.props.date).format('YYYYMMDD'));
      this.props.setCloseFlg(false);
      this.props.getCloseFlg(this.props.setCloseFlg, this.props.setErrorMsg, moment(this.props.date).format('YYYYMMDD'));
    }
  }

  render() {
    // labelを取得し、日本の日付形式に変換
    const date = moment(this.props.label, 'dddd MMM D');
    const dateLabel = `${date.format('YYYY年MM月DD日')}（${convertDayToJapanese(date.day())}）`;

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button onClick={() => this.navigate('PREV')}>戻る</button>
          <button onClick={() => this.navigate('TODAY')}>本日</button>
          <button onClick={() => this.navigate('NEXT')}>次へ</button>
        </span>
        <span className="rbc-toolbar-label">{dateLabel}</span>
        <span className="rbc-btn-group">

          <button onClick={this.openModal}>
            <FaRegCalendarAlt />
          </button>
          <Modal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={true}
            style={{
              overlay: {
                zIndex: 200,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                border: 'none',
                backgroundColor: 'transparent',
                padding: '0',
              }
            }}
          >
            <div onClick={this.closeModal} style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <ReactCalendar
                onClickDay={(date: Date) => {
                  this.props.onNavigate('DATE', date);
                  this.closeModal();
                }}
              />
            </div>
          </Modal>
        </span>
      </div>
    )
  }

  navigate = (action: 'PREV' | 'NEXT' | 'TODAY' | 'DATE') => {
    this.props.onNavigate(action);
  }
}

type GroupedResourceMap = { planName: string; rooms: TResourceMap[] };
type ExtendedResourceMap = TResourceMap & { [key: string]: any };

export const Calender: React.FC<TProps> = (props: TProps) => {

  // 24時間形式に設定
  moment.updateLocale('en', {
    longDateFormat: {
      LT: 'HH:mm', // 24時間形式
      LTS: 'HH:mm:ss', // 秒まで表示
      L: 'MM/DD/YYYY', // 日付の形式
      LL: 'MMMM D, YYYY', // '月名 日, 年'の形式
      LLL: 'MMMM D, YYYY LT', // '月名 日, 年 時間'の形式
      LLLL: 'dddd, MMMM D, YYYY LT' // '曜日, 月名 日, 年 時間'の形式
    }
  });


  const { events, resourceMaps, setEvents } = props

  const localizer = momentLocalizer(moment)

  // ローディング
  const [loading, setLoading] = useRecoilState(globalLoading);
  // 成功メッセージ
  const [, setSuccessMsg] = useRecoilState(globalSuccessMsg);
  // 失敗メッセージ
  const [, setErrorMsg] = useRecoilState(globalErrorMsg);

  const rooms: TRoom[] = useRecoilValue(globalRoom);

  const [reserves, setReserves] = useRecoilState(globalReserve);

  const [closeFlg, setCloseFlg] = useState<boolean>(false);

  const [blocks, setBlocks] = useRecoilState(globalBlock);

  const [modalFlg, setModalFlg] = useState<boolean>(false)

  const [modalData, setModalData] = useState<TModalData>()

  const moveEvent = (args: any) => {
    const { event, start, end, resourceId } = args;
    //const event = args.event
    //const start = args.start
    //const end = args.end
    //const resourceId = args.resourceId

    // 同じプランの部屋かどうかをチェック
    const sourceRoom = rooms.find(room => room.id === event.resourceId);
    const targetRoom = rooms.find(room => room.id === resourceId);

    if (!sourceRoom || !targetRoom || sourceRoom.plans[0].id !== targetRoom.plans[0].id) {
      alert('同じプランの部屋にのみ移動できます。');
      return;
    }


    const allDay = args.allDay

    const startYear = start.getFullYear();
    const startMonth = start.getMonth() + 1; // getMonthは0から始まるので、1を足します
    const startDay = start.getDate();
    const startHours = start.getHours();
    const startMinutes = start.getMinutes();
    const startSeconds = start.getSeconds();

    const endYear = end.getFullYear();
    const endMonth = end.getMonth() + 1; // getMonthは0から始まるので、1を足します
    const endDay = end.getDate();
    const endHours = end.getHours();
    const endMinutes = end.getMinutes();
    const endSeconds = end.getSeconds();

    const startFormattedDate = `${startYear}-${startMonth.toString().padStart(2, '0')}-${startDay.toString().padStart(2, '0')} ${startHours.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}:${startSeconds.toString().padStart(2, '0')}`;
    const endFormattedDate = `${endYear}-${endMonth.toString().padStart(2, '0')}-${endDay.toString().padStart(2, '0')} ${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}:${endSeconds.toString().padStart(2, '0')}`;

    if (event.id < 0) {
      return
    }

    if (window.confirm('更新しますか？')) {

      setLoading(true)

      const room = rooms.find((room) => room.id == resourceId)

      const reserve = reserves.find((reserve) => reserve.id == event.id)

      let price_total = 0
      let adult_price = 0
      let child_price = 0
      let room_price = 0

      if (room && reserve) {
        room_price = room.room_price
        if (room.pricing_flg == 1) {
          price_total = room_price
        } else {
          adult_price = room.adult_price * (reserve.adult_number ? reserve.adult_number : 0)
          child_price = room.child_price * (reserve.child_number ? reserve.child_number : 0)
          price_total = (room_price + adult_price + child_price)
        }
      }

      const updateData = {
        id: event.id,
        start_datetime: startFormattedDate,
        end_datetime: endFormattedDate,
        room_id: resourceId,
        price_total: price_total,
        adult_price: adult_price,
        child_price: child_price,
        room_price: room_price,
      }

      updateReserves(updateData, setSuccessMsg, setErrorMsg)
        .then(() => {
          getBlocks(setBlocks, setErrorMsg)
          getReserves(setReserves, setErrorMsg, `${startYear}${startMonth.toString().padStart(2, '0')}${startDay}`)
            .finally(() => {
              setLoading(false)
            })
        })
    } else {

      console.log('キャンセルしました');

    }
  }

  const resizeEvent = (args: any) => {
    const event = args.event
    const start = args.start
    const end = args.end
    const resourceId = args.resourceId

    const startYear = start.getFullYear();
    const startMonth = start.getMonth() + 1; // getMonthは0から始まるので、1を足します
    const startDay = start.getDate();
    const startHours = start.getHours();
    const startMinutes = start.getMinutes();
    const startSeconds = start.getSeconds();

    const endYear = end.getFullYear();
    const endMonth = end.getMonth() + 1; // getMonthは0から始まるので、1を足します
    const endDay = end.getDate();
    const endHours = end.getHours();
    const endMinutes = end.getMinutes();
    const endSeconds = end.getSeconds();

    const startFormattedDate = `${startYear}-${startMonth.toString().padStart(2, '0')}-${startDay.toString().padStart(2, '0')} ${startHours.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}:${startSeconds.toString().padStart(2, '0')}`;
    const endFormattedDate = `${endYear}-${endMonth.toString().padStart(2, '0')}-${endDay.toString().padStart(2, '0')} ${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}:${endSeconds.toString().padStart(2, '0')}`;

    if (event.id < 0) {
      return
    }

    if (window.confirm('更新しますか？')) {
      setLoading(true)

      const updateData = {
        id: event.id,
        start_datetime: startFormattedDate,
        end_datetime: endFormattedDate,
        room_id: resourceId
      }

      updateReserves(updateData, setSuccessMsg, setErrorMsg)
        .then(() => {
          getBlocks(setBlocks, setErrorMsg)
          getReserves(setReserves, setErrorMsg, `${startYear}${startMonth.toString().padStart(2, '0')}${startDay}`)
            .finally(() => {
              setLoading(false)
            })
        })
    } else {

      console.log('キャンセルしました');

    }
  }

  const handleSelect = (args: any) => {

    console.log('hoge');
    console.log(args);

    const start = args.start
    const end = args.end
    const resourceId = args.resourceId

    const startYear = start.getFullYear();
    const startMonth = start.getMonth() + 1; // getMonthは0から始まるので、1を足します
    const startDay = start.getDate();
    const startHours = start.getHours();
    const startMinutes = start.getMinutes();
    const startSeconds = start.getSeconds();

    const endYear = end.getFullYear();
    const endMonth = end.getMonth() + 1; // getMonthは0から始まるので、1を足します
    const endDay = end.getDate();
    const endHours = end.getHours();
    const endMinutes = end.getMinutes();
    const endSeconds = end.getSeconds();

    const startFormattedDate = `${startYear}-${startMonth.toString().padStart(2, '0')}-${startDay.toString().padStart(2, '0')} ${startHours.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}:${startSeconds.toString().padStart(2, '0')}`;
    const endFormattedDate = `${endYear}-${endMonth.toString().padStart(2, '0')}-${endDay.toString().padStart(2, '0')} ${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}:${endSeconds.toString().padStart(2, '0')}`;

    setModalData({
      roomId: resourceId,
      start: startFormattedDate,
      end: endFormattedDate,
      adultNumber: 0,
      childNumber: 0,
      type_id: args.type_id
    })

    setModalFlg(true)
  };

  const eventPropGetter = useCallback(
    (event: any, start: any, end: any, isSelected: any) => {

      let targetData: TReserve | TBlock | undefined;
      let backgroundColor = ''

      // console.log("イベントのID", event.id)

      if (event.id >= 0) {
        targetData = reserves.find((v) => v.id == event.id)
        // console.log("予約", targetData)
        if (targetData?.type_id == 1) {
          // webサイトから予約
          backgroundColor = '#E9525C'
        } else if (targetData?.type_id == 2) {
          // タブレットから予約
          backgroundColor = '#3F713D'
        } else if (targetData?.type_id == 3) {
          // 管理画面から予約
          backgroundColor = '#3E49A9'
        } else if (targetData?.type_id == 99) {
          // ブロック
          backgroundColor = '#999999'
        }

      } else {
        targetData = blocks.find((v) => (v.id * -1) == event.id)
        // console.log("ブロック", targetData)
        if (targetData?.type_id == 1) {
          backgroundColor = '#CC0000'
        } else {
          backgroundColor = '#CCCCCC'
        }
      }
      return {
        ...({
          style: {
            backgroundColor: backgroundColor,
            overflow: 'hidden',
            color: 'white',
          },
        }),
      }
    },
    [reserves.length, blocks.length]
  )

  const handleEventClick = (event: any) => {
    const reserve = reserves.find((i) => i.id == event.id)

    if (reserve) {
      const start = new Date(reserve.start_datetime)
      const end = new Date(reserve.end_datetime_with_cleaning)

      const startYear = start.getFullYear();
      const startMonth = start.getMonth() + 1; // getMonthは0から始まるので、1を足します
      const startDay = start.getDate();
      const startHours = start.getHours();
      const startMinutes = start.getMinutes();
      const startSeconds = start.getSeconds();

      const endYear = end.getFullYear();
      const endMonth = end.getMonth() + 1; // getMonthは0から始まるので、1を足します
      const endDay = end.getDate();
      const endHours = end.getHours();
      const endMinutes = end.getMinutes();
      const endSeconds = end.getSeconds();

      const startFormattedDate = `${startYear}-${startMonth.toString().padStart(2, '0')}-${startDay.toString().padStart(2, '0')} ${startHours.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}:${startSeconds.toString().padStart(2, '0')}`;
      const endFormattedDate = `${endYear}-${endMonth.toString().padStart(2, '0')}-${endDay.toString().padStart(2, '0')} ${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}:${endSeconds.toString().padStart(2, '0')}`;

      setModalData({
        id: reserve.id,
        roomId: reserve.room_id,
        start: startFormattedDate,
        end: endFormattedDate,
        adultNumber: reserve.adult_number,
        childNumber: reserve.child_number,
        name: reserve.name,
        tel: reserve.tell,
        type_id: reserve.type_id
      })

      setModalFlg(true);
    }
  };

  const groupedResourceMaps = useMemo<GroupedResourceMap[]>(() => {
    const groupedMap = new Map<string, TResourceMap[]>();
    resourceMaps.forEach(resource => {
      const room = rooms.find(room => room.id === resource.resourceId);
      const plan = room?.plans[0];
      if (plan) {
        if (!groupedMap.has(plan.plan_name)) {
          groupedMap.set(plan.plan_name, []);
        }
        groupedMap.get(plan.plan_name)!.push(resource);
      }
    });
    return Array.from(groupedMap.entries()).map(([planName, rooms]) => ({ planName, rooms }));
  }, [resourceMaps, rooms]);

  const CustomHeader: React.FC<{ group: GroupedResourceMap }> = ({ group }) => (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ fontWeight: 'bold', borderBottom: '1px solid #ccc', padding: '5px', textAlign: 'center' }}>
        {group.planName}
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        {group.rooms.map((room: TResourceMap) => (
          <div key={room.resourceId} style={{ flex: 1, padding: '5px', textAlign: 'center', borderRight: '1px solid #ccc' }}>
            {room.resourceTitle}
          </div>
        ))}
      </div>
    </div>
  );


  return (
    <div style={{ padding: '10px', position: 'relative', height: '100%' }}>
      <DnDCalendar
        defaultDate={new Date()}
        localizer={localizer}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        defaultView={Views.DAY}
        events={events}
        resizable
        resources={groupedResourceMaps.flatMap(group => group.rooms)}
        resourceIdAccessor={(resource: ExtendedResourceMap | any) => resource.resourceId}
        resourceTitleAccessor={(resource: ExtendedResourceMap | any) => resource.resourceTitle}
        draggableAccessor={(event: any) => true}
        showMultiDayTimes={true}
        step={5}
        timeslots={12}
        views={['day']}
        eventPropGetter={eventPropGetter}
        selectable
        onSelectSlot={handleSelect}
        onSelectEvent={handleEventClick}
        components={{
          resourceHeader: (headerProps: ResourceHeaderProps<object>) => {
            const resource = headerProps.resource as ExtendedResourceMap;
            const group = groupedResourceMaps.find(g => g.rooms.some(r => r.resourceId === resource.resourceId));
            return group ? <CustomHeader group={group} /> : null;
          },
          toolbar: (toolbarProps) => (
            <CustomToolbar
              {...toolbarProps}
              getReserves={getReserves}
              setReserves={setReserves}
              getCloseFlg={getCloseFlg}
              setErrorMsg={setErrorMsg}
              setCloseFlg={setCloseFlg}
            />
          )
        }}
      />
      {closeFlg && <div className="closedBlock">定休日</div>}
      {modalFlg && modalData && <RegistModal modalFlg={modalFlg} setModalFlg={setModalFlg} modalData={modalData} setModalData={setModalData} />}
    </div>
  )
}
