import React, {useEffect, useRef, useState} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import {Box, Button, Modal, Typography} from "@mui/material";
import {ImageCropComponent} from "./ImageCropComponent";

type TProps = {
  open: boolean
  setOpen: any
  index: number
  onSet: any
  setThumbnailFile: any
}

export const ThumbnailModal: React.FC<TProps> = (props: TProps) => {

  const {open, setOpen, index, onSet, setThumbnailFile} = props

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <button onClick={() => setOpen(false)} style={{ position:'absolute', top:0, right:'5px', background: 'none', border: 'none', fontSize: '3em', lineHeight:1 }}>
        ×
        </button>
        <div>
          <ImageCropComponent index={index} setThumbnailFile={setThumbnailFile}/>
          <Button variant="contained" onClick={onSet}>設定</Button>
        </div>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3
};
