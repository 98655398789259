import React, {useRef} from 'react';
import {useEffect, useState} from "react";
import {getDataSync} from "../../../service/townadmin/Room";
import '../../../css/townadmin/RoomPage.css'
import {useRecoilState} from "recoil";
import {globalErrorMsg, globalLoading, globalRoom, globalSuccessMsg} from "../../../strages/GlobalState";
import {TownadminBase} from "../../common/TownadminBase";
import {Room} from "./Room";

export const Main: React.FC = () => {

  // ローディング
  const [loading, setLoading] = useRecoilState(globalLoading);
  // 失敗メッセージ
  const [errorMsg, setErrorMsg] = useRecoilState(globalErrorMsg);

  const [rooms, setRooms] = useRecoilState(globalRoom);

  useEffect(() => {
    getDataSync(setRooms, setErrorMsg)
  }, []);

  return (
    <TownadminBase>
      <Room />
    </TownadminBase>
  )
}
