import React, {useEffect, useRef, useState} from 'react';
import {TownadminBase} from "../../common/TownadminBase";
import "react-datepicker/dist/react-datepicker.css";
import {Setting} from "./Setting";
import '../../../css/townadmin/ClosingSetting.css'
import {TClosingSetting} from "../../../types/townadmin/ClosingSetting";
import {useRecoilState} from "recoil";
import {
  globalClosingSetting,
  globalCongratulations,
  globalErrorMsg,
  globalLoading,
  globalSuccessMsg
} from "../../../strages/GlobalState";
import {getClosingSetting, getCongratulationsForApi} from "../../../service/townadmin/ClosingSetting";

export const Main: React.FC = () => {

  const [congratulations, setCongratulations] = useRecoilState(globalCongratulations);
  const [closingSettings, setClosingSettings] = useRecoilState(globalClosingSetting);

  // ローディング
  const [loading, setLoading] = useRecoilState(globalLoading);
  // 成功メッセージ
  const [, setSuccessMsg] = useRecoilState(globalSuccessMsg);
  // 失敗メッセージ
  const [, setErrorMsg] = useRecoilState(globalErrorMsg);

  useEffect(() => {
    setLoading(true)
    getCongratulationsForApi(setCongratulations)
    getClosingSetting(setClosingSettings, setErrorMsg)
      .finally(() => setLoading(false))
  }, [])

  return (
    <TownadminBase>
      <Setting/>
    </TownadminBase>
  )
}
