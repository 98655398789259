import React from "react";
import {Button, Modal, Box, Typography} from '@mui/material';
import '../../../css/townadmin/RoomPage.css'
import {useFormContext} from "react-hook-form";
import {useRecoilState} from "recoil";
import {globalRoom} from "../../../strages/GlobalState";
import _ from "lodash";
import {TRoom} from "../../../types/townadmin/Room";

type TProps = {
  open: boolean
  setOpen: any
  index: number
  room: TRoom
}

export const SettingModal: React.FC<TProps> = (props: TProps) => {

  const {open, setOpen, index, room} = props

  const [rooms, setRooms] = useRecoilState(globalRoom);

  const onChange = (key: string, value: any) => {
    let copyRooms = _.cloneDeep(rooms);
    copyRooms[index][key] = value
    setRooms(copyRooms)
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          利用可能人数設定
        </Typography>
        <button onClick={() => setOpen(false)} style={{ position:'absolute', top:0, right:'5px', background: 'none', border: 'none', fontSize: '3em', lineHeight:1 }}>
        ×
        </button>
        <div className="modal-body">
          <div>
            <input
              type="radio"
              step="300"
              value={1}
              onChange={(e) => onChange('use_user_no_limit_flg', e.target.value)}
              checked={room.use_user_no_limit_flg == 1}
            />
            <label>何人でも利用可能</label>
          </div>
          <div>
            <input
              type="radio"
              step="300"
              value={2}
              onChange={(e) => onChange('use_user_no_limit_flg', e.target.value)}
              checked={room.use_user_no_limit_flg == 2}
            />
            <label>人数を制限する</label>
          </div>
          <div className="use-user">
            <select
              disabled={room.use_user_no_limit_flg == 1}
              onChange={(e) => onChange('use_user_min', e.target.value)}
            >
              {Array.from({ length: 50 }, (_, index) => (
                <option key={index} value={index + 1} selected={room.use_user_min == index + 1}>{index + 1}</option>
              ))}
            </select>
            〜
            <select
              disabled={room.use_user_no_limit_flg == 1}
              onChange={(e) => onChange('use_user_max', e.target.value)}
            >
              {Array.from({ length: 50 }, (_, index) => (
                <option key={index} value={index + 1} selected={room.use_user_max == index + 1}>{index + 1}</option>
              ))}
            </select>
            <label>人まで</label>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3
};
