import React, {useState} from "react";
import {Button, Modal, Box, Typography} from '@mui/material';
import '../../../css/townadmin/RoomPage.css'
import {useFormContext} from "react-hook-form";
import {useRecoilState} from "recoil";
import {globalRoom} from "../../../strages/GlobalState";
import _ from "lodash";
import {TRoom, TWeekKey} from "../../../types/townadmin/Room";
import {SelectWeek} from "./SelectWeek";

const weekKeys: TWeekKey[] = [
  {key: 'sunday', name: '日曜日'},
  {key: 'monday', name: '月曜日'},
  {key: 'tuesday', name: '火曜日'},
  {key: 'wednesday', name: '水曜日'},
  {key: 'thursday', name: '木曜日'},
  {key: 'friday', name: '金曜日'},
  {key: 'saturday', name: '土曜日'},
]

type TProps = {
  open: boolean
  setOpen: any
  index: number
  room: TRoom
}

export const BusinessHoursModal: React.FC<TProps> = (props: TProps) => {

  const {open, setOpen, index, room} = props

  const [rooms, setRooms] = useRecoilState(globalRoom);

  const onChange = (key: string, value: any) => {
    let copyRooms = _.cloneDeep(rooms);
    copyRooms[index][key] = value
    setRooms(copyRooms)
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          各曜日の利用時間設定
        </Typography>
        <button onClick={() => setOpen(false)} style={{ position:'absolute', top:0, right:'5px', background: 'none', border: 'none', fontSize: '3em', lineHeight:1 }}>
        ×
        </button>

        <div className="modal-body">
          {weekKeys.map((weekKey) => (
            <SelectWeek index={index} businessHours={room.businessHours} weekKey={weekKey}/>
          ))}
        </div>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3
};
