import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../../../css/townadmin/RoomPage.css'
import { TRoom } from "../../../types/townadmin/Room";
import { Button, Modal, Box, Typography } from '@mui/material';
import { SettingModal } from "./SettingModal";
import { convertTimeToJapanese } from "../../../utils/util";
import { globalRoom } from "../../../strages/GlobalState";
import { useRecoilState } from "recoil";
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import _ from "lodash";
import { Console } from "inspector";
import { ImageUploadModal } from "./ImageUploadModal";
import { ThumbnailModal } from "./ThumbnailModal";
import { Thumbnail } from "./Thumbnail";
import { BusinessHoursModal } from "./BusinessHoursModal";

type TProps = {
  room: TRoom
  onDeleteRoom: any
  index: number
}

export const RoomTab: React.FC<TProps> = (props: TProps) => {

  const { room, onDeleteRoom, index } = props

  const [rooms, setRooms] = useRecoilState(globalRoom);

  const [open, setOpen] = useState<boolean>(false)
  const [businessHoursModalOpen, setBusinessHoursModalOpen] = useState<boolean>(false)

  const onChange = (key: string, value: any) => {
    let copyRooms = _.cloneDeep(rooms);
    copyRooms[index][key] = value
    setRooms(copyRooms)
  }

  const onChangePricingFlg = (value: any) => {
    let copyRooms = _.cloneDeep(rooms);
    copyRooms[index].pricing_flg = value
    copyRooms[index].adult_price = 0
    copyRooms[index].child_price = 0
    setRooms(copyRooms)
  }
  
  return (
    <>
      <div className="item-header">
        {room.id &&
          <Button className="red-btn" variant="contained" color="error" onClick={() => onDeleteRoom(room.id, index)}>
            削除
          </Button>
        }
      </div>
      <div>
        <div className="form-row">
          <div className="form-item">
            <label className="form-label">掲載</label>
          </div>
          <div className="form-item">
          <label className="form-label">
            <input
              type="radio"
              value={1}
              onChange={(e) => onChange('show_flg', e.target.value)}
              checked={room.show_flg == 1}
            /> 掲載する</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <label className="form-label">
            <input
              type="radio"
              value={0}
              onChange={(e) => onChange('show_flg', e.target.value)}
              checked={room.show_flg == 0}
            /> 掲載しない</label>

          </div>
        </div>


        <div className="form-row">
          <div className="form-item">
            <label className="form-label">風呂名</label>
          </div>
          <div className="form-item">
            <input
              type='text'
              value={room.room_name}
              onChange={(e) => onChange('room_name', e.target.value)}
              className="form-control form-label"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-item">
            <label className="form-label">部屋の営業時間</label>
          </div>
          <div className="form-item">
            <input
              type='time'
              value={room.room_available_start_time}
              onChange={(e) => onChange('room_available_start_time', e.target.value)}
              className="form-control form-label"
            />
            <div style={{ padding: '0 5px' }}>〜</div>
            <input
              type='time'
              value={room.room_available_end_time}
              onChange={(e) => onChange('room_available_end_time', e.target.value)}
              className="form-control form-label"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-item">
            <label className="form-label">各曜日の利用時間設定</label>
          </div>
          <div className="form-item">
            <Button className="add-btn" variant="contained" onClick={() => setBusinessHoursModalOpen(true)}>
              設定する
            </Button>
          </div>
        </div>
        <div className="form-row">
          <div className="form-item">
            <label className="form-label">利用可能人数</label>
          </div>
          <div className="form-item">
            <Button className="add-btn" variant="contained" onClick={() => setOpen(true)}>
              設定する
            </Button>
          </div>
        </div>
        <div className="form-row">
          <div className="form-item">
            <label className="form-label">インターバル（清掃時間）</label>
          </div>
          <div className="form-item">
            <input
              type='time'
              value={room.cleaning_time}
              onChange={(e) => onChange('cleaning_time', e.target.value)}
              className="form-control form-label"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-item">
            <label className="form-label">利用可能時間</label>
          </div>
          <div className="form-item">
            <input
              type='time'
              value={room.available_time}
              onChange={(e) => onChange('available_time', e.target.value)}
              className="form-control form-label"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="pricing-title">料金設定</div>
        <div className="pricing-select-area">
          <div>
            <input
              type="radio"
              value={1}
              onChange={(e) => onChangePricingFlg(e.target.value)}
              checked={room.pricing_flg == 1}
            />
            <label>
              1部屋
              <input
                className="pricing-form"
                type="number"
                value={room.room_price}
                onChange={(e) => { onChange('room_price', e.target.value) }}
                disabled={room.pricing_flg == 2}
              />円：{convertTimeToJapanese(room.available_time)}
            </label>
          </div>
          <div>
            <input
              type="radio"
              value={2}
              onChange={(e) => onChange('pricing_flg', e.target.value)}
              checked={room.pricing_flg == 2}
            />
            <label>
              1名（大人
              <input
                className="pricing-form"
                type="number"
                value={room.adult_price}
                onChange={(e) => onChange('adult_price', e.target.value)}
                disabled={room.pricing_flg == 1}
              />
              ：子供
              <input
                className="pricing-form"
                type="number"
                value={room.child_price}
                onChange={(e) => onChange('child_price', e.target.value)}
                disabled={room.pricing_flg == 1}
              />
              ）円 ＋ 1部屋
              <input
                className="pricing-form"
                type="number"
                value={room.room_price}
                onChange={(e) => onChange('room_price', e.target.value)}
                disabled={room.pricing_flg == 1}
              />円：{convertTimeToJapanese(room.available_time)}
            </label>
          </div>
        </div>
        <div className="pricing-note-area">
          <div className="form-note">備考<span>：30文字以内</span></div>
          <input
            type='text'
            value={room.note}
            onChange={(e) => onChange('note', e.target.value)}
            className="form-control form-label"
            placeholder="延長は10分400円"
            maxLength={30} // 最大文字数を設定します
          />
        </div>
      </div>
      <div>
        <div className="pricing-title">サムネイル設定</div>
        <div style={{ display: 'flex', gap: 10 }}>
          <Thumbnail index={index} />
        </div>
      </div>
      <div>
        <div className="pricing-title">部屋画像設定</div>
        <div style={{ display: 'flex', gap: 10 }}>
          {[1, 2, 3].map((num) => (
            <ImageUploadModal num={num} index={index} key={num} />
          ))}
        </div>
      </div>

      <SettingModal
        open={open}
        setOpen={setOpen}
        index={index}
        room={room}
      />
      <BusinessHoursModal
        open={businessHoursModalOpen}
        setOpen={setBusinessHoursModalOpen}
        index={index}
        room={room}
      />
    </>
  )
}
