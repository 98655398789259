import axios from "axios";
import {ClientForJson} from "../../utils/client";
import {SetterOrUpdater} from "recoil";
import {TGeneralUser} from "../../types/general/Common";
import { NavigateFunction } from 'react-router-dom';
import React from "react";

/**
 * ログインチェック
 * @param setGeneralUser
 * @param navigate
 */
export const isLogin = async (
  setGeneralUser: SetterOrUpdater<TGeneralUser>,
  navigate: NavigateFunction
) => {
  return ClientForJson.get('/api/v1/login/isLogin')
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      // ログイン中の場合
      if (data.status == 200) {
        // ユーザー情報を設定
        setGeneralUser(data.data)
        // マイページへ遷移
        navigate('/mypage')
      } else if (data.status == 401) {
        // 認証失敗の場合
        // ログイン画面に遷移
        navigate('/login')
      }
    })
};

export const isLoginSpot = async (
  setGeneralUser: SetterOrUpdater<TGeneralUser | undefined>,
) => {
  return ClientForJson.get('/api/v1/login/isLogin')
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      // ログイン中の場合
      if (data.status == 200) {
        // ユーザー情報を設定
        setGeneralUser(data.data)
      } else if (data.status == 401) {
        // 認証失敗の場合
        // ログイン画面に遷移
        setGeneralUser(undefined)
      }
    })
};

/**
 * ログイン中のユーザーを取得
 * @param setGeneralUser
 * @param navigate
 */
export const getLoginUser = async (
  setGeneralUser: SetterOrUpdater<TGeneralUser>,
) => {
  return ClientForJson.get('/api/v1/mypage/getLoginUser')
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      setGeneralUser(data.data)
    })
};

export const getCalenderDates = async (
  spotId: number,
  startDate: string,
  endDate: string,
  setDataList: React.Dispatch<React.SetStateAction<{count: number, date: string, type: number}[]>>,
) => {
  return ClientForJson.get(`/api/v1/spot/getCalenderDatas?spotId=${spotId}&startDate=${startDate}&endDate=${endDate}`)
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      if (data.status) {
        setDataList(data.data)
      }
    })
}

