import { atom, selector } from "recoil";
import {
  TGeneralUser,
  TPost,
  TPref,
  TReserveMain,
  TReview,
  TReviewSpot,
  TSpot,
  TSpotCloseFlg,
  TSpotRoom
} from "../types/general/Common";
import {TRoom} from "../types/townadmin/Room";
import {TBlock, TReserve, TReserveTypes} from "../types/townadmin/Calender";
import {TClosingSetting, TCongratulations} from "../types/townadmin/ClosingSetting";

export const globalGeneralUser = atom<TGeneralUser>({
  key: "globalGeneralUser",
  default: undefined,
});

export const globalGeneralUserSpot = atom<TGeneralUser | undefined>({
  key: "globalGeneralUserSpot",
  default: undefined,
});

export const globalLoading= atom<boolean>({
  key: "globalLoading",
  default: false,
});

export const globalSnackbarOpen = atom<boolean>({
  key: "globalSnackbarOpen",
  default: false,
});

export const globalSuccessMsg = atom<string>({
  key: "globalSuccessMsg",
  default: "",
});

export const globalErrorMsg = atom<string>({
  key: "globalErrorMsg",
  default: "",
});

export const globalReviews = atom<TReviewSpot[]>({
  key: "globalReviews",
  default: [],
});

export const globalPrefs = atom<TPref>({
  key: "globalPrefs",
  default: undefined,
});

export const globalPosts = atom<TPost[]>({
  key: "globalPosts",
  default: [],
});

export const globalSpotsPremium = atom<TSpot[]>({
  key: "globalSpotsPremium",
  default: [],
});

export const globalSpotsStandard = atom<TSpot[]>({
  key: "globalSpotsStandard",
  default: [],
});

export const globalSpotsBasic = atom<TSpot[]>({
  key: "globalSpotsBasic",
  default: [],
});

export const globalRoom = atom<TRoom[]>({
  key: "globalRoom",
  default: [],
});

export const globalSpotRoom = atom<TSpotRoom[]>({
  key: "globalSpotRoom",
  default: [],
});

export const globalReserve = atom<TReserve[]>({
  key: "globalReserve",
  default: [],
});

export const globalCloseFlg = atom<TSpotCloseFlg[]>({
  key: "globalCloseFlg",
  default: [],
});


export const globalBlock = atom<TBlock[]>({
  key: "globalBlock",
  default: [],
});

export const globalClosingSetting = atom<TClosingSetting>({
  key: "globalClosingSetting",
  default: undefined,
});

export const globalCongratulations = atom<Date[]>({
  key: "globalCongratulations",
  default: [],
});

export const globalReserveMain = atom<TReserveMain>({
  key: "globalReserveMain",
  default: undefined,
});

export const globalReserveTypes = atom<TReserveTypes[]>({
  key: "globalReserveTypes",
  default: [
    { id: 1, name: "web予約" },
    { id: 2, name: "タブレット予約" },
    { id: 3, name: "手動予約" },
    { id: 99, name: "ブロック" },
  ],
});
