import React, {useEffect, useCallback, useState} from 'react';
import {useRecoilState} from "recoil";
import {globalRoom} from "../../../strages/GlobalState";
import ReactCrop, { type Crop } from 'react-image-crop';

type TProps = {
  index: number
  setThumbnailFile: any
}

export const ImageCropComponent: React.FC<TProps> = (props: TProps) => {

  const {index, setThumbnailFile} = props

  const [rooms, setRooms] = useRecoilState(globalRoom);

  const [src, setSrc] = useState<string>();
  const [crop, setCrop] = useState<Crop>()
  const [vertical, setVertical] = useState<boolean>()

  useEffect(() => {
    let addStr = '/'
    if ("image1" in rooms[index] && rooms[index].image1) {
      addStr = ''
    }
    if (rooms[index].image1_path) {
      setSrc(addStr + rooms[index].image1_path)
    }
  }, [rooms[index].image1_path])

  useEffect(() => {
    if (!src) {
      return
    }
    // 画像のURL
    const imageUrl = src;

    // 画像オブジェクトの作成
    const img = new Image();

    // 画像が読み込まれた後に実行されるイベントリスナーを追加
    img.onload = function() {
      // 画像の幅と高さを取得
      const width = img.width;
      const height = img.height;

      setVertical(width < height)
    };

    // 画像の読み込みを開始
    img.src = imageUrl;
  }, [src])

  const getCroppedImg = useCallback(async (imageSrc: string, crop: Crop): Promise<File> => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement('canvas');
    const scale = vertical ? image.naturalWidth / 400 : image.naturalHeight / 400
    canvas.width = crop.width!;
    canvas.height = crop.height!;
    const ctx = canvas.getContext('2d');

    ctx!.drawImage(
      image,
      crop.x! * scale,
      crop.y! * scale,
      vertical ? image.width! : image.height!,
      vertical ? image.width! : image.height!,
      0,
      0,
      crop.width!,
      crop.height!
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        const file = new File([blob], "thumbnail.png", { type: "image/png" });
        resolve(file);
      }, 'image/png');
    });
  }, [vertical]);

  const onCropComplete = useCallback((crop: Crop) => {
    if (!src || !crop.width || !crop.height) return;

    getCroppedImg(src, crop).then((croppedFile) => {
      // ここで croppedFile を使用する
      setThumbnailFile(croppedFile)
    });
  }, [getCroppedImg, src]);

  return (
    <div>
      {rooms[index].image1_path ? (
        <ReactCrop
          crop={crop}
          minWidth={400}
          minHeight={400}
          maxWidth={400}
          maxHeight={400}
          onChange={c => setCrop(c)}
          onComplete={onCropComplete}>
          <img
            src={src}
            style={{maxWidth: vertical ? 400 : 'none', maxHeight: vertical ? 'none': 400, height: 'auto', width: 'auto', objectFit: 'scale-down'}} />
        </ReactCrop>
      ):
        <><span style={{fontWeight: 'bold'}}>[部屋画像1]</span>を選択してください。</>
      }
    </div>
  );
};
