import React, {ReactNode, useEffect, useState} from 'react';
import {CircularProgress, Snackbar, Alert} from '@mui/material';
import {useRecoilState, useRecoilValue} from "recoil";
import {
  globalErrorMsg,
  globalGeneralUser,
  globalLoading,
  globalSnackbarOpen,
  globalSuccessMsg
} from "../../strages/GlobalState";

type TProps = {
  children: ReactNode
}

export const TownadminBase: React.FC<TProps> = (props: TProps) => {

  const {children} = props

  // ローディング
  const [loading, setLoading] = useRecoilState(globalLoading);
  // 成功メッセージ
  const [successMsg, setSuccessMsg] = useRecoilState(globalSuccessMsg);
  // 失敗メッセージ
  const [errorMsg, setErrorMsg] = useRecoilState(globalErrorMsg);

  // 画面の高さ
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSuccessMsg("")
    setErrorMsg("");
  };

  // 画面の高さを取得し高さを設定
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {loading &&
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)', // 半透明の黒色を指定
            width: '100%',
            height: '100%', // windowHeightではなく100%を使用
            position: 'fixed', // fixedに変更する
            top: 0,
            left: 0,
            zIndex: 9999
          }}>
              <CircularProgress />
          </div>
      }
      <Snackbar open={successMsg || errorMsg ? true : false} autoHideDuration={2000} onClose={handleClose}>
        <Alert severity={successMsg ? 'success' : 'error'} sx={{ width: '100%' }}>{successMsg ? successMsg : errorMsg}</Alert>
      </Snackbar>
      {children}
    </>
  )
}
