import React, {useEffect, useRef, useState} from 'react';
import {useRecoilState} from "recoil";
import {globalRoom} from "../../../strages/GlobalState";
import ReactCrop, { type Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Box, Button, Modal, Typography} from "@mui/material";
import _ from "lodash";
import {ImageCropComponent} from "./ImageCropComponent";
import {ThumbnailModal} from "./ThumbnailModal";

type TProps = {
  index: number
}

export const Thumbnail: React.FC<TProps> = (props: TProps) => {

  const {index} = props

  const [rooms, setRooms] = useRecoilState(globalRoom);

  const [image, setImage] = useState<string | undefined>(rooms[index].thumbnail_path && '/' + rooms[index].thumbnail_path);

  const [thumbnailOpen, setThumbnailOpen] = useState<boolean>(false)

  const [thumbnailFile, setThumbnailFile] = useState<File | undefined>(rooms[index].thumbnail && rooms[index].thumbnail)

  const onSet = () => {
    if (thumbnailFile) {
      let copyRooms = _.cloneDeep(rooms);
      copyRooms[index]['thumbnail'] = thumbnailFile
      const imageUrl = URL.createObjectURL(thumbnailFile);

      setRooms(copyRooms)
      setImage(imageUrl)
      setThumbnailOpen(false)
    }
  }

  useEffect(() => {
    if (thumbnailFile) {
      const imageUrl = URL.createObjectURL(thumbnailFile);
      setImage(imageUrl)
    }
  }, [thumbnailFile])

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <Button style={{fontSize:18}} onClick={() => setThumbnailOpen(true)}>サムネイルを設定する</Button>
      </div>
      <img src={image ? image : "/img/noimage.png"} alt="uploaded" style={{ width: 400, height: 400, objectFit:'cover' }} />
      <ThumbnailModal open={thumbnailOpen} setOpen={setThumbnailOpen} index={index} onSet={onSet} setThumbnailFile={setThumbnailFile}/>
    </div>
  );
};
