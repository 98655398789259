import {SetterOrUpdater} from "recoil";
import {TRoom} from "../../types/townadmin/Room";
import {ClientForJson} from "../../utils/client";
import {TClosingSetting, TCongratulations} from "../../types/townadmin/ClosingSetting";

export const getClosingSetting = async (
  setClosingSettings: SetterOrUpdater<TClosingSetting>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return ClientForJson.get('/townadmin/api/v1/closingSetting/getClosingSetting')
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      if (data.status == 200) {
        setClosingSettings(data.data)
      } else if (data.status == 400) {
        setErrorMsg(data.message)
      }
    })
};

export const updateClosingSetting = async (
  registData: TClosingSetting,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return ClientForJson.post('/townadmin/api/v1/closingSetting/updateClosingSetting', registData)
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      if (data.status == 200) {
      } else if (data.status == 400) {
        setErrorMsg(data.message)
      }
    })
};

export const getCongratulationsForApi = async (
  setCongratulations: SetterOrUpdater<Date[]>,
) => {
  fetch('https://holidays-jp.github.io/api/v1/2024/date.json')
    .then(response => {
      // レスポンスが正常でなければエラーを投げる
      if (!response.ok) {
        throw new Error('ネットワークレスポンスが異常です');
      }
      // レスポンスデータをJSON形式で解析
      return response.json();
    })
    .then(data => {
      // 解析された祝日データをコンソールに表示
      const holidayDates = Object.keys(data);

      setCongratulations(holidayDates.map(dateString => {
        const dateTest = new Date(dateString)
        return new Date(dateTest.getFullYear(), dateTest.getMonth(), dateTest.getDate())
      }))
    })
    .catch(error => {
      // エラー処理
      console.error('祝日データの取得中にエラーが発生しました:', error);
    });
  // return ClientForJson.get('https://holidays-jp.github.io/api/v1/2024/datetime.json')
  //   .then(function (response) {
  //     if (response.status == 200) {
  //       return response.data;
  //     }
  //   })
  //   .then(function (data) {
  //     console.log(data)
  //   })
};
