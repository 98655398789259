import React, {useEffect, useRef, useState} from 'react';
import { useRecoilState } from "recoil";
import { globalRoom } from "../../../strages/GlobalState";
import { Box, Modal, Typography, Button } from "@mui/material";
import _ from "lodash";

type TProps = {
  num: number;
  index: number
};

export const ImageUploadModal: React.FC<TProps> = (props: TProps) => {
  const { num, index } = props;

  const [rooms, setRooms] = useRecoilState(globalRoom);
  const [image, setImage] = useState<string | undefined>(rooms[index][`image${num}_path`] && '/' + rooms[index][`image${num}_path`]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: any): any => {
    let copyRooms = _.cloneDeep(rooms);

    const fileData: File | null = event.target.files ? event.target.files[0] : null;
    if (fileData) {
      const imageUrl = URL.createObjectURL(fileData);
      setImage(imageUrl);

      copyRooms[index][`image${num}`] = fileData
      copyRooms[index][`image${num}_path`] = imageUrl
    }
    setRooms(copyRooms)
  }

  const handleFileDelete = () => {
    let copyRooms = _.cloneDeep(rooms);
    copyRooms[index][`image${num}`] = undefined
    copyRooms[index][`image${num}_path`] = undefined
    setImage(undefined)
    setRooms(copyRooms)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>{`部屋画像 ${num}`}</div>
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <Button style={{fontSize:18}} onClick={() => fileInputRef.current?.click()}>画像選択</Button>
        <Button style={{fontSize:18}} onClick={handleFileDelete}>×</Button>
      </div>
      <img src={image ? image : "/img/noimage.png"} alt="uploaded" style={{ width: 300, height: 200, objectFit: 'contain', backgroundColor: '#f0f0f0' }} />
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept="image/*"
      />
    </div>
  );
};
