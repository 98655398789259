import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../../css/townadmin/RoomPage.css'
import {TRoom} from "../../../types/townadmin/Room";
import {Button, Modal, Box, Typography, Tab, CircularProgress} from '@mui/material';
import {SettingModal} from "./SettingModal";
import {convertTimeToJapanese} from "../../../utils/util";
import {globalErrorMsg, globalLoading, globalRoom, globalSuccessMsg} from "../../../strages/GlobalState";
import {useRecoilState} from "recoil";
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {RoomTab} from "./RoomTab";
import _ from "lodash";
import {deleteRoom, getDataSync, updateRooms} from "../../../service/townadmin/Room";

export const Room: React.FC = () => {

  const [rooms, setRooms] = useRecoilState(globalRoom);

  // ローディング
  const [loading, setLoading] = useRecoilState(globalLoading);
  // 成功メッセージ
  const [successMsg, setSuccessMsg] = useRecoilState(globalSuccessMsg);
  // 失敗メッセージ
  const [errorMsg, setErrorMsg] = useRecoilState(globalErrorMsg);

  const [selectNo, setSelectNo] = useState<string>('0');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectNo(String(newValue));
  };

  const addRoom = () => {
    const copyRooms = _.cloneDeep(rooms);
    const data: TRoom = {
      available_time: '00:30:00',
      cleaning_time: '00:05:00',
      room_name: '新規部屋',
      room_price: 1000,
      use_user_max: 6,
      use_user_min: 2,
      use_user_no_limit_flg: 1,
      adult_price: 0,
      child_price: 0,
      room_available_start_time: '10:00:00',
      room_available_end_time: '22:00:00',
      pricing_flg: 1
    }
    setRooms([...copyRooms, data])
  }

  const onDeleteRoom = (roomId: number | undefined, index: number) => {
    if(window.confirm('削除しますか？')) {
      setLoading(true)
      if (roomId) {
        deleteRoom(roomId ? roomId : -1, setSuccessMsg, setErrorMsg)
          .then(async (result) => {
            if (result) {
              await getDataSync(setRooms, setErrorMsg)
            }
          })
          .finally(() => {
            setSelectNo('0')
            setLoading(false)
          })
      } else {
        // let copyRooms = _.cloneDeep(rooms);
        // copyRooms.splice(index, 1);
        // setRooms(copyRooms)
      }
    }
  }

  const onSubmit = () => {
    setLoading(true)
    updateRooms(rooms, setSuccessMsg, setErrorMsg)
      .then(async () =>{
        await getDataSync(setRooms, setErrorMsg)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="container">
      <div className="container-header">
        <Button className="btn btn-primary btn-sm checkbox-toggle" variant="contained" onClick={addRoom}>
          部屋追加
        </Button>
      </div>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={selectNo}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                {rooms.map((room, index) => (
                  <Tab label={room.room_name} value={String(index)} key={index} />
                ))}
              </TabList>
            </Box>
            {rooms.map((room: TRoom, index) => (
              <TabPanel value={String(index)} key={index}>
                <RoomTab
                  room={room}
                  onDeleteRoom={onDeleteRoom}
                  index={index}/>
              </TabPanel>
            ))}
          </TabContext>
          {rooms.length > 0 &&
            <Button className="btn btn-primary btn-sm checkbox-toggle" variant="contained" onClick={onSubmit}>
              登録
            </Button>
          }
        </Box>
    </div>
  )
}
