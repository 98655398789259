import {TRoom} from "../types/townadmin/Room";

export const convertTimeToJapanese = (timeString: string) => {
  // 時間と分を抽出するためにコロンで分割します
  const parts = timeString.split(':');

  // 時間と分を整数に変換します
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);

  return hours == 0 ? `${minutes}分` : `${hours}時間${minutes}分`
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}.${month}.${day}`;
}

export const formatDateSpecial = (dateString: string) => {
  const options: any = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('ja-JP', options).format(date).replace(/\//g, '.');
}

export const removePTags = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return Array.from(doc.body.querySelectorAll('p')).map(p => p.textContent).join('\n');
}

export const getWednesdays = async (months: number, weekNo: number) => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + months, 0); // 月の最終日
  const dates: Date[] = [];

  for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
    if (currentDate.getDay() === weekNo) {
      dates.push(new Date(currentDate)); // 新しいDateオブジェクトを作成して追加
    }
  }

  return dates;
};

export const getCongratulations = async (months: number) => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + months, 0); // 月の最終日
  const dates: Date[] = [];

  return dates;
};


export const splitHoliday = async (
  dates: Date[],
  weekNos: number[],
  congratulations: Date[]
) => {
  const baseDate: Date = dates[0]
  const regularHoliday: string[] = []
  const holiday: string[] = []
  const congratulationsDates: string[] = []

  dates.map((date) => {
    if (weekNos.some((i) => i == date.getDay())) {
      regularHoliday.push(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`);
    } else {
      const isExistence = congratulations.some((congratulation) => congratulation.toISOString().split("T")[0] == date.toISOString().split("T")[0])
      if (isExistence) {
        congratulationsDates.push(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`)
      } else {
        holiday.push(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`);
      }
    }
  })

  return {
    regularHoliday: regularHoliday,
    holiday: holiday,
    congratulationsDates: congratulationsDates
  };
};


export const addTimeToDate = async (dateString: string, timeString: string) => {
  // 日付を生成
  const date = new Date(dateString);

  // 時間、分、秒を時間文字列から抽出
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  // 時間、分、秒をそれぞれ加算
  date.setHours(date.getHours() + hours);
  date.setMinutes(date.getMinutes() + minutes);
  date.setSeconds(date.getSeconds() + seconds);

  return date;
}
