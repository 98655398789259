import React, {useState} from 'react';
import '../../../css/general/PrefTime.scss'
import {Base} from "../../common/Base";
import {TimeBarComponent} from "./TimeBarComponent";


export const Main: React.FC = () => {

  
  return (
    <Base>
      <div className="react-container tests-from">
        <div className='info'>
          <div className='info-left'>
            <div className="title">一の湯</div>
            <div className='label'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M12.44 0.910034H2.74C1.22674 0.910034 0 2.13677 0 3.65003V13.35C0 14.8633 1.22674 16.09 2.74 16.09H12.44C13.9533 16.09 15.18 14.8633 15.18 13.35V3.65003C15.18 2.13677 13.9533 0.910034 12.44 0.910034Z" fill="#666666"/>
                <path d="M7.04 11.16H4.67V10.52H7.04V9.66003H4.67V9.01003H6.73L3.82 4.54003H5.1L6.53 6.96003C6.95 7.72003 7.18 8.11003 7.56 8.79003H7.62C8.03 8.11003 8.26 7.70003 8.65 6.96003L10.07 4.54003H11.34L8.45 9.01003H10.5V9.66003H8.14V10.52H10.5V11.16H8.14V13.19H7.04V11.16Z" fill="white"/>
              </svg>
              <p>大人/￥000〜0,000</p>
            </div>
            <div className='label'>              
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.44 0.910034H2.74C1.22674 0.910034 0 2.13677 0 3.65003V13.35C0 14.8633 1.22674 16.09 2.74 16.09H12.44C13.9533 16.09 15.18 14.8633 15.18 13.35V3.65003C15.18 2.13677 13.9533 0.910034 12.44 0.910034Z" fill="#666666"/>
                <path d="M7.93 3.26501C7.92 3.42501 7.92 3.61501 7.91 3.81501C8.02 5.39501 8.67 10.935 13.17 13.005C12.92 13.195 12.64 13.495 12.51 13.735C9.42 12.275 8.1 9.20501 7.52 6.80501C6.99 9.20501 5.76 12.095 2.72 13.735C2.56 13.525 2.28 13.235 2 13.055C6.87 10.565 6.87 4.84501 6.95 3.26501H7.93Z" fill="white"/>
              </svg>
              <p>2〜6名まで</p>
            </div>
            <a href='' className='info-btn'>時間予約へ</a>
          </div>
          <div className='info-right'>
            <img src="webroot/img/noimage.png" alt="test" />
          </div>
        </div>
        <div className='time'>
          <div className='time-title'>
            <span></span><p>空き状況（目安）</p>
          </div>
          <div className='time-table'>
            <div className='time-table-btn'>
              <img id='table-btn-left' src="webroot/img/spot-slid.png" alt="" />
              <img id='table-btn-right' src="webroot/img/spot-slid.png" alt="" />
            </div>
            {/* <TimeBarComponent />中のコード修正 */}
          </div>
        </div>
    </div>
    </Base>
  )
}

