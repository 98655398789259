import React, {useEffect, useRef, useState} from 'react';
import {TownadminBase} from "../../common/TownadminBase";
import {Autocomplete, Button, Chip, MenuItem, Select, TextField} from "@mui/material";
import Popper, { PopperProps } from '@mui/material/Popper';
import DatePicker, { registerLocale } from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import ja from 'date-fns/locale/ja';
import "react-datepicker/dist/react-datepicker.css";
import {TClosingSetting, TClosingSettingForm} from "../../../types/townadmin/ClosingSetting";
import {useRecoilState} from "recoil";
import {
  globalClosingSetting,
  globalCongratulations,
  globalErrorMsg,
  globalLoading,
  globalSuccessMsg
} from "../../../strages/GlobalState";
import {getWednesdays, splitHoliday} from "../../../utils/util";
import {
  getClosingSetting,
  getCongratulationsForApi,
  updateClosingSetting
} from "../../../service/townadmin/ClosingSetting";

const SET_MONTH = 12

const defaultValues: TClosingSettingForm = {
  dates: []
};

export const Setting: React.FC = () => {
  registerLocale('ja', ja);

  // ローディング
  const [, setLoading] = useRecoilState(globalLoading);
  // 成功メッセージ
  const [, setSuccessMsg] = useRecoilState(globalSuccessMsg);
  // 失敗メッセージ
  const [, setErrorMsg] = useRecoilState(globalErrorMsg);

  const [congratulations, setCongratulations] = useRecoilState(globalCongratulations);

  const [closingSettings, setClosingSettings] = useRecoilState(globalClosingSetting);

  const [weekNo, setWeekNo] = useState<number>()

  const [checkFlgSunday, setCheckFlgSunday] = useState<boolean>()
  const [checkFlgMonday, setCheckFlgMonday] = useState<boolean>()
  const [checkFlgTuesday, setCheckFlgTuesday] = useState<boolean>()
  const [checkFlgWednesday, setCheckFlgWednesday] = useState<boolean>()
  const [checkFlgThursday, setCheckFlgThursday] = useState<boolean>()
  const [checkFlgFriday, setCheckFlgFriday] = useState<boolean>()
  const [checkFlgSaturday, setCheckFlgSaturday] = useState<boolean>()

  const [congratulationsFlg, setCongratulationsFlg] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues
  } = useForm<TClosingSettingForm>({
    defaultValues: defaultValues
  });

  useEffect(() => {
    if (closingSettings) {

      const regularHolidayArr = closingSettings.regular_holiday ? closingSettings.regular_holiday.split(',') : []
      const holiday = closingSettings.holiday ? closingSettings.holiday.split(',') : []
      const congratulations = closingSettings.congratulations ? closingSettings.congratulations.split(',') : []

      let strDates = [...regularHolidayArr, ...holiday]

      strDates = [...strDates, ...congratulations]

      setValue('dates', strDates.map(dateString => {
        const dateTest = new Date(dateString)
        return new Date(dateTest.getFullYear(), dateTest.getMonth(), dateTest.getDate())
      }))

      setCheckFlgSunday(closingSettings.check_flg_sunday)
      setCheckFlgMonday(closingSettings.check_flg_monday)
      setCheckFlgTuesday(closingSettings.check_flg_tuesday)
      setCheckFlgWednesday(closingSettings.check_flg_wednesday)
      setCheckFlgThursday(closingSettings.check_flg_thursday)
      setCheckFlgFriday(closingSettings.check_flg_friday)
      setCheckFlgSaturday(closingSettings.check_flg_saturday)

      setCongratulationsFlg(closingSettings.congratulations_flg)

    }
  }, [closingSettings])

  const onChangeWeek = async (key: string, value: boolean) => {

    let weekNo = 0

    switch (key) {
      case 'Sunday':
        weekNo = 0
        setCheckFlgSunday(value)
        break
      case 'Monday':
        weekNo = 1
        setCheckFlgMonday(value)
        break
      case 'Tuesday':
        weekNo = 2
        setCheckFlgTuesday(value)
        break
      case 'Wednesday':
        weekNo = 3
        setCheckFlgWednesday(value)
        break
      case 'Thursday':
        weekNo = 4
        setCheckFlgThursday(value)
        break
      case 'Friday':
        weekNo = 5
        setCheckFlgFriday(value)
        break
      case 'Saturday':
        weekNo = 6
        setCheckFlgSaturday(value)
        break
    }

    setValue('dates', await updateCheck(weekNo, value))
  }

  const updateCheck = async (weekNo: number, flg: boolean) => {
    let beforeMonthDates: Date[] = []
    let baseDates = getValues('dates')

    if (flg) {
      let afterMonthDates: Date[] = await getWednesdays(SET_MONTH, weekNo)

      baseDates = [...baseDates, ...afterMonthDates]
    } else {
      beforeMonthDates = await getWednesdays(SET_MONTH, weekNo)

      // 削除したい日付のミリ秒の配列を作成
      let timestampsToRemove = beforeMonthDates.map(date => date.getTime());

      // 元の配列から削除したい日付を除外
      baseDates = baseDates.filter(date => !timestampsToRemove.includes(date.getTime()));
    }

    return baseDates
  }

  const onChangeCheck = (value: boolean) => {
    let baseDates = getValues('dates')
    if (value) {
      baseDates = [...baseDates, ...congratulations];
    } else {
      // 削除したい日付のミリ秒の配列を作成
      let timestampsToRemove = congratulations.map(date => date.getTime());
      // 元の配列から削除したい日付を除外
      baseDates = baseDates.filter(date => !timestampsToRemove.includes(date.getTime()));
    }

    setValue('dates', baseDates)
    setCongratulationsFlg(value)
  }

  const onSubmit = async (data: TClosingSettingForm) => {
    setLoading(true)

    const weekNos = []

    if (checkFlgSunday) weekNos.push(0)
    if (checkFlgMonday) weekNos.push(1)
    if (checkFlgTuesday) weekNos.push(2)
    if (checkFlgWednesday) weekNos.push(3)
    if (checkFlgThursday) weekNos.push(4)
    if (checkFlgFriday) weekNos.push(5)
    if (checkFlgSaturday) weekNos.push(6)

    const splitHolidayData = await splitHoliday(data.dates, weekNos, congratulations)

    const registData: TClosingSetting = {
      id: closingSettings ? closingSettings.id : undefined,
      spot_id: closingSettings ? closingSettings.spot_id : undefined,
      check_flg_sunday: checkFlgSunday,
      check_flg_monday: checkFlgMonday,
      check_flg_tuesday: checkFlgTuesday,
      check_flg_wednesday: checkFlgWednesday,
      check_flg_thursday: checkFlgThursday,
      check_flg_friday: checkFlgFriday,
      check_flg_saturday: checkFlgSaturday,
      congratulations_flg: congratulationsFlg,
      regular_holiday: splitHolidayData.regularHoliday.join(','),
      holiday: splitHolidayData.holiday.join(','),
      congratulations: splitHolidayData.congratulationsDates.join(','),
    }

    updateClosingSetting(registData, setErrorMsg)
      .then(() => {
        getClosingSetting(setClosingSettings, setErrorMsg)
          .finally(() => setLoading(false))
      })
  }

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', padding: 50, gap: 30}}>
        <div style={{display: 'flex', gap: 8, flexDirection: 'column'}}>
          <div style={{fontWeight: 'bold'}}>定休日</div>
          <div style={{display: 'flex', gap: 15}}>
            <div style={{display: 'flex', gap: 5}}>
              日
              <input type="checkbox" checked={checkFlgSunday} onChange={(e) => onChangeWeek('Sunday', e.target.checked)}/>
            </div>
            <div style={{display: 'flex', gap: 5}}>
              月
              <input type="checkbox" checked={checkFlgMonday} onChange={(e) => onChangeWeek('Monday', e.target.checked)}/>
            </div>
            <div style={{display: 'flex', gap: 5}}>
              火
              <input type="checkbox" checked={checkFlgTuesday} onChange={(e) => onChangeWeek('Tuesday', e.target.checked)}/>
            </div>
            <div style={{display: 'flex', gap: 5}}>
              水
              <input type="checkbox" checked={checkFlgWednesday} onChange={(e) => onChangeWeek('Wednesday', e.target.checked)}/>
            </div>
            <div style={{display: 'flex', gap: 5}}>
              木
              <input type="checkbox" checked={checkFlgThursday} onChange={(e) => onChangeWeek('Thursday', e.target.checked)}/>
            </div>
            <div style={{display: 'flex', gap: 5}}>
              金
              <input type="checkbox" checked={checkFlgFriday} onChange={(e) => onChangeWeek('Friday', e.target.checked)}/>
            </div>
            <div style={{display: 'flex', gap: 5}}>
              土
              <input type="checkbox" checked={checkFlgSaturday} onChange={(e) => onChangeWeek('Saturday', e.target.checked)}/>
            </div>
          </div>
        </div>
        <div style={{display: 'flex', gap: 5}}>
          <div style={{fontWeight: 'bold'}}>祝日</div>
          <input
            type="checkbox"
            name="congratulations_swich"
            checked={congratulationsFlg}
            onChange={(e) => onChangeCheck(e.target.checked)}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{fontWeight: 'bold'}}>自由設定</div>
          <div>
            <Controller
              name="dates"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selected={null}
                  dateFormatCalendar="yyyy年 MM月"
                  locale="ja"
                  onMonthChange={(date: Date) => {
                    // 月が変更された際の処理
                    console.log(`新しい月: ${date.getMonth() + 1}`);
                  }}
                  onChange={(date: Date) => {
                    if (date) {
                      const isSelected = field.value.some(
                        (selectedDate: Date) =>
                          selectedDate
                            .toISOString()
                            .split("T")[0] ===
                          date
                            .toISOString()
                            .split("T")[0]
                      );

                      if (isSelected) {
                        field.onChange(
                          field.value.filter(
                            (
                              selectedDate: Date
                            ) =>
                              selectedDate
                                .toISOString()
                                .split(
                                  "T"
                                )[0] !==
                              date
                                .toISOString()
                                .split(
                                  "T"
                                )[0]
                          )
                        );
                      } else {
                        field.onChange([
                          ...field.value,
                          date
                        ]);
                      }
                    }
                  }}
                  inline
                  dayClassName={(date: Date) => {
                    return field.value.some(
                      (selectedDate: Date) =>
                        selectedDate
                          .toISOString()
                          .split("T")[0] ===
                        date
                          .toISOString()
                          .split("T")[0]
                    )
                      ? "highlighted"
                      : null;
                  }}
                  className="date-picker"
                />
              )}
            />
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>保存</Button>
        </div>
      </div>
    </>
  )
}
