import React, {useEffect, useState} from 'react';
import {Calender} from "./Calender";
import {TownadminBase} from "../../common/TownadminBase";
import {getBlocks, getReserves, getRooms, getCloseFlg} from "../../../service/townadmin/Calender";
import {useRecoilState, useRecoilValue} from "recoil";
import {globalBlock, globalErrorMsg, globalReserve, globalReviews, globalCloseFlg, globalRoom} from "../../../strages/GlobalState";
import {TReserve, TResourceMap, TEvent, TBlock} from "../../../types/townadmin/Calender";
import {TRoom} from "../../../types/townadmin/Room";
import { TSpotCloseFlg } from '../../../types/general/Common';

export const Main: React.FC = () => {

  // 失敗メッセージ
  const [, setErrorMsg] = useRecoilState(globalErrorMsg);

  const [, setReserves] = useRecoilState(globalReserve);

  const [, setRooms] = useRecoilState(globalRoom);

  const [, setBlocks] = useRecoilState(globalBlock);

  const [, setCloseFlg] = useState<boolean>(false);


  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const currentDate = `${year}${month}${day}`;

  useEffect(() => {
    getRooms(setRooms, setErrorMsg)
    getCloseFlg(setCloseFlg, setErrorMsg, currentDate)
    getReserves(setReserves, setErrorMsg, currentDate)
    getBlocks(setBlocks, setErrorMsg)
  }, [])

  return (
    <TownadminBase>
      <Content />
    </TownadminBase>
  )
}

const Content: React.FC = () => {

  const reservs: TReserve[] = useRecoilValue(globalReserve);
  const rooms: TRoom[] = useRecoilValue(globalRoom);
  const blocks: TBlock[] = useRecoilValue(globalBlock);

  const [events, setEvents] = useState<TEvent[]>([])

  const [resourceMaps, setResourceMaps] = useState<TResourceMap[]>([])

  useEffect(() => {
    const eventList: TEvent[] = []
    reservs.map((reserv) => {
      eventList.push({
        id: reserv.id,
        title: reserv.name,
        start: new Date(reserv.start_datetime),
        end: new Date(reserv.end_datetime_with_cleaning),

        resourceId: reserv.room_id
      })
    })
    blocks.map((block) => {
      if (block.type_id !== 1) {
        eventList.push({
          id: block.id * -1,
          title: 'ブロック',
          start: new Date(block.start_datetime),
          end: new Date(block.end_datetime),
          resourceId: block.room_id
        });
      }
      /*
      eventList.push({
        id: block.id * -1,
        title: block.type_id == 1 ? '掃除' : 'ブロック',
        start: new Date(block.start_datetime),
        end: new Date(block.end_datetime),
        resourceId: block.room_id
      })
      */
    })
    setEvents(eventList)

    const resourceMapList: TResourceMap[] = []
    rooms.map((room) => {
      resourceMapList.push({
        resourceId: room.id ? room.id : 0,
        resourceTitle: room.room_name
      })
    })
    setResourceMaps(resourceMapList)
  }, [reservs, blocks, rooms])

  return (
    <div>
      <Calender
        events={events}
        resourceMaps={resourceMaps}
        setEvents={setEvents}
        rooms={rooms}
      />
    </div>
  )
}