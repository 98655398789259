import {SetterOrUpdater} from "recoil";
import {TRoom} from "../../types/townadmin/Room";
import {ClientForJson} from "../../utils/client";
import {TBlock, TReserve} from "../../types/townadmin/Calender";

export const getRooms = async (
  setRooms: SetterOrUpdater<TRoom[]>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return ClientForJson.get('/townadmin/api/v1/room/getRooms')
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      if (data.status == 200) {
        setRooms(data.data)
      } else if (data.status == 400) {
        setErrorMsg(data.message)
      }
    })
};


export const getCloseFlg = async (
  setCloseFlg: SetterOrUpdater<boolean>,
  setErrorMsg: SetterOrUpdater<string>,
  date: string
) => {
  return ClientForJson.get('/townadmin/api/v1/closingSetting/isClosed/' + date)
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      if (data.status == 200) {
        setCloseFlg(data.data.is_closed);
        return data.data;
      } else if (data.status == 400) {
        setErrorMsg(data.message);
        return data.message;
      }
    })
};



export const getReserves = async (
  setReserves: SetterOrUpdater<TReserve[]>,
  setErrorMsg: SetterOrUpdater<string>,
  date: string
) => {
  return ClientForJson.get('/townadmin/api/v1/calender/getReserves/' + date)
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      if (data.status == 200) {
        setReserves(data.data)
      } else if (data.status == 400) {
        setErrorMsg(data.message)
      }
    })
};

export const getBlocks = async (
  setBlocks: SetterOrUpdater<TBlock[]>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return ClientForJson.get('/townadmin/api/v1/calender/getBlocks')
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      if (data.status == 200) {
        setBlocks(data.data)
      } else if (data.status == 400) {
        setErrorMsg(data.message)
      }
    })
};

export const updateReserves = async (
  updateData: any,
  setSuccessMsg: SetterOrUpdater<string>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return ClientForJson.post('/townadmin/api/v1/calender/updateReserves', updateData)
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      const result = data.data

      if (result.status == 200) {
        setSuccessMsg(result.message)
      } else if (result.status == 400) {
        setErrorMsg(result.message)
      }
    })
};


export const registReserves = async (
  data: any,
  setSuccessMsg: SetterOrUpdater<string>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return ClientForJson.post('/townadmin/api/v1/calender/registReserves', data)
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      const result = data.data

      if (result.status == 200) {
        setSuccessMsg(result.message)
      } else if (result.status == 400) {
        setErrorMsg(result.message)
      }
    })
};

export const deleteReserves = async (
  data: any,
  setSuccessMsg: SetterOrUpdater<string>,
  setErrorMsg: SetterOrUpdater<string>
) => {
  return ClientForJson.post('/townadmin/api/v1/calender/deleteReserves', data)
    .then(function (response) {
      if (response.status == 200) {
        return response.data;
      }
    })
    .then(function (data) {
      const result = data.data

      if (result.status == 200) {
        setSuccessMsg(result.message)
      } else if (result.status == 400) {
        setErrorMsg(result.message)
      }
    })
};
