// modules
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

// ローカルファイル
import {Main as PrefTime} from './pages/general/prefTime/Main'

export const Root: React.FC = () => {
  return (
    <div>
      <BrowserRouter >
        <Routes>
          <Route path="/prefTime" element={<PrefTime />} />
        </Routes>
      </BrowserRouter >
    </div >
  );
}