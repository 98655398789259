import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import {
  globalBlock,
  globalErrorMsg,
  globalLoading,
  globalReserve,
  globalReviews,
  globalRoom, globalSuccessMsg,
  globalReserveTypes
} from "../../../strages/GlobalState";
import {Box, Modal, Typography, Button} from "@mui/material";
import {TModalData, TReserveTypes} from "../../../types/townadmin/Calender";
import {TRoom} from "../../../types/townadmin/Room";
import _ from "lodash";
import {
  deleteReserves,
  getBlocks,
  getReserves,
  registReserves,
  updateReserves
} from "../../../service/townadmin/Calender";
import moment from 'moment';


type TProps = {
  modalFlg: boolean
  setModalFlg: React.Dispatch<React.SetStateAction<boolean>>
  modalData: TModalData
  setModalData: React.Dispatch<React.SetStateAction<TModalData | undefined>>
}

export const RegistModal: React.FC<TProps> = (props: TProps) => {

  const {modalFlg, setModalFlg, modalData, setModalData} = props

  const rooms: TRoom[] = useRecoilValue(globalRoom);

  const types: TReserveTypes[] = useRecoilValue(globalReserveTypes);

  const [reserves, setReserves] = useRecoilState(globalReserve);

  const [blocks, setBlocks] = useRecoilState(globalBlock);

  // ローディング
  const [, setLoading] = useRecoilState(globalLoading);
  // 成功メッセージ
  const [, setSuccessMsg] = useRecoilState(globalSuccessMsg);
  // 失敗メッセージ
  const [, setErrorMsg] = useRecoilState(globalErrorMsg);

  const [disableBtn, setDisableBtn] = useState(true)

  useEffect(() => {
    isDisableBtn(modalData)
  }, [])

  const onChange = (key: string, value: any) => {
    let copyModalData: TModalData = _.cloneDeep(modalData);
    copyModalData[key] = value
    setModalData(copyModalData)
    isDisableBtn(copyModalData)
  }

  const isDisableBtn = (copyModalData: TModalData) => {
    const roomId = copyModalData.roomId
    const start = copyModalData.start
    const end = copyModalData.end
    const name = copyModalData.name
    const type_id = copyModalData.type_id

    if (roomId != 0 && start && end && (name || type_id == 99)) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }

  const onSubmit = () => {

    setLoading(true)

    const room = rooms.find((room) => room.id == modalData.roomId)

    let price_total = 0
    let adult_price = 0
    let child_price = 0
    let room_price = 0

    if (room) {
      room_price = room.room_price
      if (room.pricing_flg == 1) {
        price_total = room_price
      } else {
        adult_price = room.adult_price * (modalData.adultNumber ? modalData.adultNumber : 0)
        child_price = room.child_price * (modalData.childNumber ? modalData.childNumber : 0)
        price_total = (room_price + adult_price + child_price)
      }
    }

    let data: any = {
      is_local: false,
      name: modalData.name,
      tell: modalData.tel,
      start_datetime: modalData.start,
      end_datetime: modalData.end,
      reserve_status: 1,
      payment_status: 1,
      payment_method: 1,
      price_total: price_total,
      adult_price: adult_price,
      child_price: child_price,
      room_price: room_price,
      adult_number: modalData.adultNumber,
      child_number: modalData.childNumber,
      room_id: modalData.roomId,
      type_id: modalData.type_id
    }

    let execFunc = registReserves

    if (modalData.id) {
      execFunc = updateReserves
      data['id'] = modalData.id
    }

    const dateString = moment(modalData.start).format('YYYYMMDD');

    execFunc(data, setSuccessMsg, setErrorMsg)
      .then(() => {
        getBlocks(setBlocks, setErrorMsg)
        getReserves(setReserves, setErrorMsg, dateString)
          .finally(() => {
            setModalFlg(false)
            setLoading(false)
          })
      })
  }


  const convertTimeToMinutes = (timeString: string) => {
    // 時間と分を':'で分割
    const [hours, minutes] = timeString.split(':');

    // 時間を分に変換し、分と合算
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  }

  const getDifferenceInMinutes = (dateTimeStart: string, dateTimeEnd: string) => {
    // Dateオブジェクトを作成
    const start = new Date(dateTimeStart);
    const end = new Date(dateTimeEnd);

    // 2つの日時の差分をミリ秒で計算
    const differenceInMilliseconds = end.getTime() - start.getTime();

    // ミリ秒を分に変換（1分 = 60秒 = 60000ミリ秒）
    return differenceInMilliseconds / 60000;
  }

  const onDelete = () => {
    console.log(modalData);
    if (modalData.id) {
      setLoading(true)
      if(window.confirm('更新しますか？')) {
        const data: any = {
          id: modalData.id,
          room_id: modalData.roomId,
        }

        deleteReserves(data, setSuccessMsg, setErrorMsg)
          .then(() => {
            getBlocks(setBlocks, setErrorMsg)
            getReserves(setReserves, setErrorMsg,'2024-04-18f')
              .finally(() => {
                setModalFlg(false)
                setLoading(false)
              })
          })
      } else {
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      open={modalFlg}
      onClose={() => setModalFlg(!modalFlg)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          予約
        </Typography>
        <button onClick={() => setModalFlg(!modalFlg)} style={{ position:'absolute', top:0, right:'5px', background: 'none', border: 'none', fontSize: '3em', lineHeight:1 }}>
        ×
        </button>

        <div className="modal-body">
          <div className="form-row">
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '30%'}}>
              <label className="form-label">部屋</label>
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '70%'}}>
              <select
                style={{height: 41, width: '100%', border: '1px solid #ced4da', borderRadius: '0.25rem'}}
                onChange={(e) => onChange('roomId', e.target.value)}
              >
                {rooms && rooms.length > 0 && rooms.map((room) => (
                  <option value={room.id} selected={room.id == modalData.roomId}>{room.room_name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '30%'}}>
              <label className="form-label">予約タイプ</label>
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '70%'}}>
              <select
                style={{height: 41, width: '100%', border: '1px solid #ced4da', borderRadius: '0.25rem'}}
                onChange={(e) => onChange('type_id', e.target.value)}
              >
                {types && types.length > 0 && types.map((type) => (
                  <option value={type.id} selected={type.id == modalData.type_id}>{type.name}</option>
                ))}
              </select>
            </div>
          </div>          
          <div className="form-row">
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '30%'}}>
              <label className="form-label">利用時間(開始)</label>
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '70%'}}>
              <input
                type='datetime-local'
                value={modalData.start}
                onChange={(e) => onChange('start', e.target.value)}
                className="form-control form-label"
              />
            </div>
          </div>
          <div className="form-row">
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '30%'}}>
              <label className="form-label">利用時間(清掃終了)</label>
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '70%'}}>
              <input
                type='datetime-local'
                value={modalData.end}
                onChange={(e) => onChange('end', e.target.value)}
                className="form-control form-label"
              />
            </div>
          </div>
          <div className="form-row">
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '30%'}}>
              <label className="form-label">お名前</label>
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '70%'}}>
              <input
                type='text'
                value={modalData.name}
                onChange={(e) => onChange('name', e.target.value)}
                className="form-control form-label"
              />
            </div>
          </div>
          <div className="form-row">
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '30%'}}>
              <label className="form-label">電話番号</label>
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '70%'}}>
              <input
                type='text'
                value={modalData.tel}
                onChange={(e) => onChange('tel', e.target.value)}
                className="form-control form-label"
              />
            </div>
          </div>
          <div className="form-row">
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '30%'}}>
              <label className="form-label">利用人数</label>
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, width: '70%', gap: 10}}>
              大人：
              <select
                style={{height: 41, width: 40, border: '1px solid #ced4da', borderRadius: '0.25rem'}}
                onChange={(e) => onChange('adultNumber', e.target.value)}
              >
                {[...Array(101).keys()].map((number) => (
                  <option key={number} value={number} selected={number == modalData.adultNumber}>
                    {number}
                  </option>
                ))}
              </select>
              子供：
              <select
                style={{height: 41, width: 40, border: '1px solid #ced4da', borderRadius: '0.25rem'}}
                onChange={(e) => onChange('childNumber', e.target.value)}
              >
                {[...Array(101).keys()].map((number) => (
                  <option key={number} value={number} selected={number == modalData.childNumber}>
                    {number}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, width: '100%', gap: 30}}>
              <Button variant="contained" onClick={onSubmit} disabled={disableBtn}>保存</Button>
              <Button variant="contained" color="error" onClick={onDelete} disabled={!modalData.id}>削除</Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '80%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3
};

