import React, {useEffect, useState} from "react";
import {TBusinessHour, TRoom, TWeekKey} from "../../../types/townadmin/Room";
import _ from "lodash";
import {useRecoilState} from "recoil";
import {globalRoom} from "../../../strages/GlobalState";

type TProps = {
  index: number
  businessHours?: TBusinessHour
  weekKey: TWeekKey
}
export const SelectWeek: React.FC<TProps> = (props: TProps) => {

  const {index, businessHours, weekKey} = props

  const [rooms, setRooms] = useRecoilState(globalRoom);

  const [selectBox, setSelectBox] = useState<boolean>(false)

  useEffect(() => {
    if (businessHours) {
      setSelectBox(businessHours[`${weekKey.key}_start_time`] ? true : false)
    }
  }, [businessHours])

  const onChangeSelect = (val: any) => {

    setSelectBox(val)

    if (!val) {
      let copyRooms = _.cloneDeep(rooms);
      let businessHoursTmp = copyRooms[index].businessHours

      if (!businessHoursTmp) {
        businessHoursTmp = {}
      }

      businessHoursTmp[`${weekKey.key}_start_time`] = ''
      businessHoursTmp[`${weekKey.key}_end_time`] = ''
      copyRooms[index].businessHours = businessHoursTmp

      setRooms(copyRooms)
    }
  }
  const onChange = (key: string, value: any) => {
    let copyRooms = _.cloneDeep(rooms);
    let businessHoursTmp = copyRooms[index].businessHours

    if (!businessHoursTmp) {
      businessHoursTmp = {
        spot_id: copyRooms[index].spot_id,
        room_id: copyRooms[index].id
      }
    }

    businessHoursTmp[`${key}_time`] = value
    copyRooms[index].businessHours = businessHoursTmp
    setRooms(copyRooms)
  }

  return (
    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
      <div style={{display: 'flex', alignItems: 'center', width: '30%', gap: 20}}>
        <input type="checkbox" checked={selectBox} onChange={(e) => onChangeSelect(e.target.checked)}/>
        <label>{weekKey.name}</label>
      </div>
      <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
        <input
          type='time'
          value={businessHours ? businessHours[`${weekKey.key}_start_time`] : ''}
          onChange={(e) => onChange(`${weekKey.key}_start`, e.target.value)}
          className="form-control form-label"
          disabled={!selectBox}
        />
        <div>〜</div>
        <input
          type='time'
          value={businessHours ? businessHours[`${weekKey.key}_end_time`] : ''}
          onChange={(e) => onChange(`${weekKey.key}_end`, e.target.value)}
          className="form-control form-label"
          disabled={!selectBox}
        />
      </div>
    </div>
  )
}
