import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Main as Room} from './pages/townadmin/roomPage/Main'
import {Main as Calendar} from './pages/townadmin/calendar/Main'
import {Main as ClosingSetting} from './pages/townadmin/closingSetting/Main'
//import {Main as SpotRoomList} from './pages/general/spotRoomList/Main'
import {RecoilRoot, useRecoilState} from 'recoil';
import {Root} from "./Root";

//一般

// 部屋
const rootEle: any = document.getElementById('root')

if (rootEle) {
  ReactDOM.createRoot(rootEle).render(
    <React.StrictMode>
      <RecoilRoot>
        <Root />
      </RecoilRoot>
    </React.StrictMode>
  );
}

const rootSpotRoomListEle: any = document.getElementById('root-spot-room-list')

/*
if (rootSpotRoomListEle) {
  ReactDOM.createRoot(rootSpotRoomListEle).render(
    <React.StrictMode>
      <RecoilRoot>
        <SpotRoomList />
      </RecoilRoot>
    </React.StrictMode>
  );
}
*/

// Townadmin

// 部屋
const townadminRoomPageRootEle: any = document.getElementById('townadmin-room-page')

if (townadminRoomPageRootEle) {
  ReactDOM.createRoot(townadminRoomPageRootEle).render(
    <React.StrictMode>
      <RecoilRoot>
        <Room />
      </RecoilRoot>
    </React.StrictMode>
  );
}


// カレンダー
const townadminCalendarPageRootEle: any = document.getElementById('townadmin-calendar-page')

if (townadminCalendarPageRootEle) {
  ReactDOM.createRoot(townadminCalendarPageRootEle).render(
    <React.StrictMode>
      <RecoilRoot>
        <Calendar />
      </RecoilRoot>
    </React.StrictMode>
  );
}

// 休館設定
const townadminClosingSettingRootEle: any = document.getElementById('townadmin-closing-setting')

if (townadminClosingSettingRootEle) {
  ReactDOM.createRoot(townadminClosingSettingRootEle).render(
    <React.StrictMode>
      <RecoilRoot>
        <ClosingSetting />
      </RecoilRoot>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
